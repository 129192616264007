/** @jsx jsx */
import { jsx, Flex, Box, Themed } from 'theme-ui';
import { Document } from '@contentful/rich-text-types';
import {
  ContainerBottomPadding,
  ContainerTopPadding,
  ContentSplit,
} from '@sprinklr/shared-lib/@types/types';
import { ContainedMedia, Container, ContentBlock } from '@sprinklr/shared-lib';
import {
  useContainerPadding,
  useFlexGap,
  useFlexGrow,
} from '@sprinklr/shared-lib/hooks';
import ContentShowcaseTemplate from '../contentShowcaseTemplate';
import DocumentTemplate from '../document';
import HeroContent from '../heroTemplate/heroComponents/HeroContent';
import LayoutSection from '../LayoutSection';
import BrandGalleryTemplate from '../brandGallery';

export type ContentColumnsProps = {
  sectionId?: string;
  sectionTitle?: Document;
  sectionSubTitle?: Document;
  columnItems: any;
  direction: 'HORIZONTAL (Columns)' | 'VERTICAL (Rows)';
  maxWidth: '100%' | '1520px' | '1360px' | '1020px';
  topMargin: ContainerTopPadding;
  bottomMargin: ContainerBottomPadding;
  contentSplit?: ContentSplit;
  gap?: 'SMALL' | 'MEDIUM' | 'LARGE';
  itemsAlignment?: 'STRETCH' | 'CENTER' | 'START' | 'END';
  reverseColumnOrder?: boolean;
};
const ContentColumnsTemplate: React.FC<ContentColumnsProps> = ({
  direction,
  sectionId,
  sectionTitle,
  sectionSubTitle,
  columnItems = [],
  maxWidth,
  topMargin,
  bottomMargin,
  contentSplit,
  gap,
  itemsAlignment,
  reverseColumnOrder,
}) => {
  const padding = { top: topMargin, bottom: bottomMargin };
  const [pt, pb] = useContainerPadding(padding);
  const contentFlex = columnItems.length === 2 ? useFlexGrow(contentSplit) : 1;

  const flexGap = useFlexGap(gap);
  const alignment = getAlignmentValue(itemsAlignment);

  const outerSx = { pt, pb };
  const isVerticalDirection = direction === 'VERTICAL (Rows)' || false;
  if (maxWidth === '100%') {
    outerSx.px = 0;
    outerSx.maxWidth = maxWidth;
  }

  let columnDirection = 'column';
  if (reverseColumnOrder === true) columnDirection = 'column-reverse';

  const renderTitleSection = () => {
    return (
      <HeroContent
        headline={sectionTitle}
        subHeadline={sectionSubTitle}
        isSingleHeroCentered={true}
        containerSx={{ mb: 4 }}
      />
    );
  };

  return (
    <Container id={sectionId} containerSx={outerSx}>
      {(sectionTitle || sectionSubTitle) && renderTitleSection()}
      <Flex
        sx={{
          flexDirection: [
            columnDirection,
            columnDirection,
            columnDirection,
            isVerticalDirection ? columnDirection : 'row',
          ],
          justifyContent: 'center',
          maxWidth,
          mx: 'auto',
        }}
      >
        {columnItems &&
          columnItems.map((item, index) => {
            const templateComponent = templateMap[item.__typename];
            const flex = index === 0 ? contentFlex : 1;
            const alignSelf =
              item.__typename !== 'ContentfulTemplateBrandGallery' && alignment;
            const notFistChild = index !== 0;
            const notLastChild = index !== columnItems.length - 1;
            const mt =
              notFistChild &&
              (isVerticalDirection ? [2, flexGap] : [1, null, null, 0]);
            const mb =
              notLastChild &&
              (isVerticalDirection ? [2, flexGap] : [1, null, null, 0]);
            const ml =
              notFistChild &&
              (isVerticalDirection ? [0, 0] : [0, 0, 0, flexGap]);
            const mr =
              notLastChild &&
              (isVerticalDirection ? [0, 0] : [0, 0, 0, flexGap]);
            return (
              <Box sx={{ mt, mb, ml, mr, flex, alignSelf }} key={index}>
                {templateComponent?.(item) ?? templateMap.default(item)}
              </Box>
            );
          })}
      </Flex>
    </Container>
  );
};

export default ContentColumnsTemplate;

const PlaceholderTemplateComponent = props => {
  return (
    <Container>
      <Themed.h3>{props.__typename}</Themed.h3>
      <pre>{JSON.stringify(props, null, 2)}</pre>
    </Container>
  );
};

const templateMap = {
  ContentfulContentBlock: props => <ContentBlock {...props} />,
  ContentfulLayoutSection: props => <LayoutSection {...props} />,
  ContentfulTemplateBrandGallery: props => {
    return <BrandGalleryTemplate noContainer={true} {...props} />;
  },
  ContentfulTemplateContentShowcase: props => (
    <ContentShowcaseTemplate showcaseIcon={props.icon?.gatsbyImageData} />
  ),
  ContentfulTemplateDocument: props => (
    <DocumentTemplate widthType={props.width} />
  ),
  ContentfulMedia: props => <ContainedMedia {...props} />,
  default: PlaceholderTemplateComponent,
};

const getAlignmentValue = contentfulAlignString => {
  switch (contentfulAlignString) {
    case 'CENTER':
      return 'center';
    case 'START':
      return 'flex-start';
    case 'END':
      return 'flex-end';
    default:
      return 'stretch';
  }
};
